var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "CCol",
            {
              staticClass: "mb-2 mb-0 map-container",
              attrs: { lg: "8", md: "6", sm: "6" }
            },
            [
              _c(
                "CCard",
                { staticClass: "admin-card" },
                [
                  _c(
                    "CCardBody",
                    {
                      staticClass: "p-0 overflow-none map-container",
                      staticStyle: { height: "600px" }
                    },
                    [
                      _c("CEmbed", {
                        staticClass: "embedded-content-lg",
                        attrs: {
                          id: "map-frame",
                          type: "iframe",
                          src: _vm.mapSrc,
                          ratio: "1by1"
                        }
                      }),
                      _c("iframe", {
                        staticClass: "embedded-content-lg ",
                        attrs: {
                          id: "map-frame",
                          src: _vm.mapSrc,
                          width: "100%",
                          height: "100%"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { staticClass: "mb-sm-2 mb-0", attrs: { lg: "", sm: "6" } },
            [
              _c(
                "CCard",
                { staticClass: "admin-card" },
                [
                  _c(
                    "CCardHeader",
                    { staticClass: "admin-card-header" },
                    [
                      _c("CIcon", { attrs: { name: "cil-map" } }),
                      _vm._v(" Vehicle Tracking ")
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    { staticClass: "m-0 p-0 overflow-auto" },
                    [_c("StatusGrid", { attrs: { items: _vm.status } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }