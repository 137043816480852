
<template>
<div>
    <CRow style="height:100%">
      <CCol lg="8" md="6" sm="6" class="mb-2 mb-0 map-container">
        <CCard class="admin-card">
          <CCardBody class="p-0 overflow-none map-container" style="height:600px">
            <CEmbed
            id="map-frame"
            class = "embedded-content-lg"
            type="iframe"
            :src="mapSrc"
            ratio="1by1"

            >
            </CEmbed>
              <iframe id="map-frame" class="embedded-content-lg "
                  :src="mapSrc"
                  width="100%"
                  height="100%"
                  >
              </iframe>
            
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="" sm="6" class="mb-sm-2 mb-0">
        <CCard class="admin-card">
          <CCardHeader class="admin-card-header"
            ><CIcon name="cil-map" /> Vehicle Tracking
          </CCardHeader >
          <CCardBody class="m-0 p-0 overflow-auto">
            <StatusGrid :items="status"
              
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
</div>

</template>


<style scoped>
.admin-card {
  background-color: var(--light);
  height: 100%;
}

.admin-card-header {
  background-color: var(--light);
  padding: 5px;
}
.embedded-content-lg {
  width: 100%;
  height: 100%;
  border-style: none;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;

}

#map-frame{
  min-height: 400px;
}
.map-container{
  min-height: 400px;
  height: 100%;
}

</style>


<script>
import LiveTrackingWidget from "@/widgets/LiveTrackingWidget";
import StatusGrid from "@/components/tables/StatusGrid"

const defaultMapSrc = "https://demo1.aarcomm.io/worldmap"; 


export default {
  name: "LiveTrackingView",
  components: {
    LiveTrackingWidget,
    StatusGrid, 
  },
  props:{
        status: Array, 
    },
    data(){
        return {
            mapSrc:"https://demo1.aarcomm.io/worldmap", 
        };
    },
    methods: {
        /** Re-Orders the Array based on Recent Activity / Device */
        reorderArray(){

        }
    },
    created(){
        
    },
  mounted() {

  },
};
</script>